<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Statement</h1>
      </div>
      <StatementForm :viewMode="true" :eventName="'updateStatement'" @updateStatement="updateStatement" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import StatementForm from '@/components/financial/StatementForm.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'Statement',
  components: {
    StatementForm,
  },
  mounted(){
    this.loadStatement();
  },
  methods:{
    async loadStatement(){
      try{
        const loginInfo = Common.getLoginInfo();
        const statementId = this.$route.params.id;
        const statement = await Financial.loadStatement(this.apiUrl, statementId, loginInfo);
        this.setCurrentStatement(statement);
      }catch(err){
        console.log(err);
      }
    },
    async updateStatement(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const statementInfo = Object.assign({}, form);
        const statementId = this.$route.params.id;
        statementInfo.date = dayjs(statementInfo.date).unix();
        const statement = await Financial.updateStatement(this.apiUrl, statementId, statementInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentStatement']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
