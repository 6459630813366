<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled && this.form.status !== 'Accept' && this.form.status !== 'Reject'" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">Statement information</h2>
    <div class="card info-card">
      <el-form ref="trade-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Bank Name">
            <el-input v-model="form.bankName"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Account Number">
            <el-input v-model="form.accountNumber"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Statement Date" style="max-width: 550px;">
            <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="Select Date" />
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Statment File" style="max-width: 550px;">
            <el-upload
            :action="uploadUrl"
            :limit="1"
            :file-list="statementFiles"
            :on-preview="onPreview"
            :on-success="onUploaded">
            <el-button size="small" type="primary">Upload</el-button>
            <div slot="tip" class="el-upload__tip">Only accept pdf, jpg, png file.</div>
          </el-upload>
        </el-form-item>
      </div>
    </el-form>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'StatementForm',
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      bankName: '',
      accountNumber: '',
      date: '',
      statementFile: '',
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      uploadUrl: '',
      statementFiles: [],
      disabled,
      form,
    };
  },
  mounted(){
    this.uploadUrl = `${this.apiUrl}/statement/file`;
  },
  methods:{
    onPreview(file){
      const fileUrl = this.form.statementFile;
      window.open(fileUrl, "_blank");
    },
    async onUploaded(response, file, fileList){
      const fileUrl = response['image_url'];
      this.form.statementFile = fileUrl;
    },
    startEdit(){
      this.disabled = false;
    },
    save(){
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
  },
  computed: mapState({
    currentStatement: state => state.financial.currentStatement,
    apiUrl: state => state.apiUrl,
  }),
  watch: {
    'currentStatement': function(){
      this.form = {
        bankName: this.currentStatement.bank_name,
        accountNumber: this.currentStatement.account_number,
        statementFile: '',
        date: dayjs.unix(this.currentStatement.statement_date),
      };
      const statementFileUrl = this.currentStatement.statement_file;
      if(statementFileUrl){
        const statementFileName = statementFileUrl.substring(statementFileUrl.lastIndexOf('/')+1);
        const statementFile = {
          name: statementFileName,
          url: statementFileUrl,
        };
        this.statementFiles.push(statementFile);
      }
    }
  }
}
</script>

<style lang="scss">
.related-user-card{
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .related-user-form{
    margin-left: 15px;
    margin-top: 20px;
  }
}

.info-card{
  padding: 30px;
  margin-bottom: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.other-expense-type{
  .el-form-item__content{
    margin-left: 30px !important;
  }
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
